const en_US = {
  platform: 'Intelligent AI system',
  Log_In: 'Login',
  Home: 'Home',
  InterviewLibrary: 'Interview Library',
  StudyLibrary: 'Study Library',
  PersonasLibrary: 'Personas Library',
  Report: 'Report',
  User: 'User',
  Search: ' Search',
  Welcome: 'Welcome,',
  Confirm: 'Confirm',
}
export default en_US
